import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import PostEntry from "../../components/post-entry"
import SEO from "../../components/seo"

const renderPreviousLink = props => {
    const {
        pageContext: { pageNumber },
    } = props

    let previousLink = null

    if (!pageNumber || pageNumber === 1) {
        return null
    } else if (2 === pageNumber) {
        previousLink = `/blog`
    } else if (2 < pageNumber) {
        previousLink = `/page/${pageNumber - 1}`
    }

    return (
        <Link className="prev page-numbers" to={previousLink}>
            Newer posts
        </Link>
    )
}

const renderNextLink = props => {
    const {
        pageContext: { hasNextPage, pageNumber },
    } = props

    if (hasNextPage) {
        return (
            <Link className="next page-numbers" to={`/page/${pageNumber + 1}`}>
                Older posts
            </Link>
        )
    } else {
        return null
    }
}

const BlogArchive = props => {
    const {
        pageContext: { nodes, pageNumber },
    } = props

    return (
        <Layout cssClasses="blog">
            <SEO
                title="Blog"
                description="WordPress tips and helpful articles"
            />
            {nodes &&
                nodes.map(post => <PostEntry key={post.postId} post={post} />)}

            <div className="pagination_wrap">
                <nav className="navigation pagination" role="navigation">
                    <h2 className="screen-reader-text">Posts navigation</h2>
                    <div className="nav-links">
                        {renderNextLink(props)}

                        <span
                            aria-current="page"
                            className="page-numbers current"
                        >
                            {pageNumber}
                        </span>
                        {renderPreviousLink(props)}
                    </div>
                </nav>
            </div>
        </Layout>
    )
}

export default BlogArchive
